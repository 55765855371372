// reset.scss
@import url('//cdn.jsdelivr.net/npm/xeicon@2.3.3/xeicon.min.css');
@import url('https://cdn.jsdelivr.net/gh/sunn-us/SUIT/fonts/static/woff2/SUIT.css');
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.6/dist/web/static/pretendard.css');

// BootStrap Root Reset
$theme-colors: (
  'primary': #35403a,
  'primary-dark': #2a332e,
  'primary-light': #abcbff,
  'secondary': #a8a8a8,
  'success': #198754,
  'info': #1171ff,
  'warning': #ffc107,
  'danger': #da1e28,
  'light': #f8f9fa,
  'dark': #2c2f38,
  'gray': #525252,
  'gray-dark': #343a40,

  'border': #e0e0e0,
  'main-bg': #e8f4ff,
  'ts': transparent,

  'blue': #0d6efd,
  'indigo': #6610f2,
  'purple': #6f42c1,
  'pink': #d63384,
  'red': #dc3545,
  'orange': #fd7e14,
  'yellow': #ffc107,
  'green': #198754,
  'teal': #20c997,
  'cyan': #0dcaf0,

  'black': #000,
  'white': #fff,
  'gray-100': #f8f9fa,
  'gray-200': #e9ecef,
  'gray-300': #dee2e6,
  'gray-400': #c6c6c6,
  'gray-500': #adb5bd,
  'gray-600': #8d8d8d,
  'gray-700': #495057,
  'gray-800': #343a40,
  'gray-900': #212529,
  'disabled-back': #818e88,
  'disabled-color': #e0e4e2,
  'disabled-pri': #35403a,
);
$font-family-sans-serif: 'Pretendard', sans-serif;

// add
:root {
  --fs-10: 0.625rem;
  --fs-11: 0.6875rem;
  --fs-12: 0.75rem;
  --fs-14: 0.875rem;
  --fs-16: 1rem;
  --fs-18: 1.125rem;
  --fs-20: 1.25rem;
  --fs-24: 1.5rem;
  --fs-28: 1.75rem;
  --fs-32: 2rem;
  --fs-36: 2.25rem;
  --fs-48: 3rem;
  --fs-64: 4rem;
}

[class*=' xi-'],
[class^='xi-'] {
  font-size: var(--fs-24);
}
