// 로그인
#signin {
  .container {
    display: grid;
    justify-content: center;
    align-items: center;

    .logo-area {
      width: 100%;
      height: auto;
      display: grid;
      align-items: center;
      justify-content: center;

      .logo-box {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 74px;
          height: 55px;
          background-size: contain;
          margin-bottom: 16px;
        }
      }

      .title-area {
        // align-items: center;
        // justify-content: center;
        width: 55px;
        height: 20px;
        margin-left: 10px;
        flex: 1;
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;

        h2 {
          font-family: Pretendard;
          font-weight: 500;
          font-size: 1rem;
          line-height: 1.25rem;
          letter-spacing: 0.01rem;
          color: #28282b;
          margin-bottom: 0;
        }
      }
    }

    .form-box {
      justify-content: center;
      align-items: center;
      width: 464px;
      height: auto;
      border-radius: 6px;
      border: 1px solid #e0e0e7;
      padding: 24px;
      gap: 32px;
      margin-top: 32px;
      padding: 24px;

      .title-area {
        margin-bottom: 4rem;

        &.signin2-area {
          margin-bottom: 0;
          font-weight: 600;
        }
      }

      .btn-area.finds {
        span {
          width: 1px;
          height: 1rem;
          background: var(--bs-border);
        }
      }

      .btn-area {
        width: 100%;
        height: 3rem;
        background-color: #5658b7;
        border-radius: 0.375rem;
        margin-top: 40px !important;
        margin-bottom: 0;
      }

      .invalid-feedback {
        color: #da1e28;
      }

      .form-control {
        width: 100%;
      }
    }

    .service-text {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-top: 1rem;

      p {
        font-weight: bold;
        font-size: 0.75rem;
        color: #6f6f6f;
      }

      &.signin2-service {
        margin-top: 5rem;
      }
    }
  }
}

//pwd
#pwd {
  .container {
    display: grid;
    justify-content: center;
    align-items: center;

    .logo-area {
      width: 9rem;
      height: 1.25rem;
      display: flex;

      .logo-box {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 5rem;
          height: 1.25rem;
        }
      }

      .title-area {
        // align-items: center;
        // justify-content: center;
        width: 55px;
        height: 20px;
        margin-left: 10px;
        flex: 1;
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;

        h2 {
          font-family: Pretendard;
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.25rem;
          letter-spacing: 0.01rem;
          color: #28282b;
          margin-bottom: 0;
        }
      }
    }

    .form-box {
      justify-content: center;
      align-items: center;
      width: 350px;
      height: auto;
      border-radius: 6px;
      border: 1px solid #e0e0e7;
      padding: 24px;
      gap: 40px;
      margin-top: 32px;
      padding: 24px;

      .title-area {
        margin-bottom: 4rem;

        &.signin2-area {
          margin-bottom: 0;
        }
      }

      .btn-area.finds {
        span {
          width: 1px;
          height: 1rem;
          background: var(--bs-border);
        }
      }

      .btn-area {
        width: 18.875rem;
        height: 3rem;
        background-color: #5658b7;
        border-radius: 0.375rem;
        margin-top: 40px !important;
        margin-bottom: 0;
      }

      .invalid-feedback {
        color: #da1e28;
      }

      .pwd-change-text-box {
        h6 {
          color: #000000;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 1rem;
          text-align: center;
        }

        p {
          color: #000000;
          font-size: 14px;
          font-weight: 400;
          text-align: center;
          word-break: keep-all;
        }
      }
    }

    .service-text {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-top: 1rem;

      p {
        font-weight: bold;
        font-size: 0.75rem;
        color: #6f6f6f;
      }

      &.signin2-service {
        margin-top: 5rem;
      }
    }
  }
}

// 에러
#errors {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--bs-gray-100);
}

#only-errors {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: var(--bs-gray-100);
}

//회원 관리
#user {
  background-color: #fafafd;

  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: center;
    align-self: stretch;

    .title-text {
      color: #000000;
      font-family: 'Pretendard';
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.16px;
    }

    button {
      border: 1px solid #387fff;
      border-radius: 6px;
      padding: 14px 16px;
      color: var(--text-primary, #161616);
      font-family: 'Pretendard';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
    }
  }

  .menu-top-article {
    display: flex;
    padding: 16px;
    flex-direction: column;
    gap: 16px;
    border: 1px solid var(--border-border-02, #c6c6c6);
    border-radius: 14px;
    background: #fff;
    margin-bottom: 16px;

    .grid-top-box {
      display: grid;
      grid-template-columns: 100px 1fr;
      align-items: center;
    }

    .register {
      border-radius: 6px;
      background-color: #387fff;
      color: #fff;
      width: 57px;
      height: 48px;
    }
  }

  .menu-article {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-end;
    align-self: stretch;
    gap: 16px;
    border: 1px solid var(--border-border-02, #c6c6c6);
    border-radius: 14px;
    background: #fff;

    table {
      align-self: stretch;
      border-top: 1px solid var(--Weak-Gray, #f1f1f8);

      th {
        padding: 8px;
        background: var(--Pale-Gray, #fafafd);
        color: var(--text-secondary, #525252);
        font-family: 'Pretendard';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.16px;
        text-align: left;
      }

      .th-center {
        text-align: center;
      }

      .th-right {
        text-align: right;
      }

      .th-left {
        text-align: left;
      }

      td {
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 8px;
        text-align: left;
        cursor: default;

        .table-tr {
          background-color: #fafafd;
          padding: 8px 71px 8px 48px;
          align-items: center;
          gap: 16px;
          align-self: stretch;
        }

        button {
          border: 1px solid var(--Dark-Blue, #5658b7);
          border-radius: 6px;
          padding: 7px;
          height: 42px;
          width: 75px;
          font-size: 14px;
        }
      }

      .state-label {
        font-weight: 600;
        font-size: 16px;
        text-align: center;

        &.sucess {
          color: #42be65;
        }

        &.cancel {
          color: #da1e28;
        }

        &.end {
          color: #9b9b9b;
        }
      }

      .td-text-title {
        width: 130px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
      }

      .td-text-detail {
        width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
      }
    }

    p {
      color: var(--text-primary, #161616);
      font-family: 'Pretendard';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
      margin-bottom: 8px;
    }

    input {
      border-radius: 6px;
      border: 1px solid var(--border-border-01, #e0e0e0);
      width: 100%;
      height: 48px;
      padding: 15px 16px;
    }

    .article-text {
      color: var(--Black, #000);
      font-family: 'Pretendard';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.16px;
    }
  }
}

//회원관리 디테일
#member-detail {
  background-color: #fafafd;

  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: center;
    align-self: stretch;

    .title-text {
      color: #000000;
      font-family: 'Pretendard';
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.16px;
    }

    button {
      border: 1px solid #387fff;
      border-radius: 6px;
      padding: 14px 16px;
      color: var(--text-primary, #161616);
      font-family: 'Pretendard';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
    }
  }

  .member-detail-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: auto;
    height: auto;
    padding: 16px;
    gap: 16px;
    margin: 24px 0px 32px 0px;
    border-radius: 14px;
    border: 1px solid var(--border-border-02, #c6c6c6);
    background: #fff;

    .title-area {
      border-bottom: 0;

      h2 {
        font-weight: 700;
        font-style: normal;
        font-family: Pretendard;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; /* 155.556% */
        letter-spacing: 0.16px;
        margin: 0;
        color: #161616;
      }
    }

    .input-area {
      width: 100%;
    }

    .member-title-text {
      font-size: 20px;
      font-weight: 600;
      color: #000000;
      margin-bottom: 0px;
    }

    .auth-label-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .authlabel {
        font-size: 16px;
        font-weight: bold;

        &.notauth {
          color: #6f6f6f;
        }

        &.sucess {
          color: #326bff;
        }
      }
    }

    .profile-box {
      display: flex;
      align-items: center;
      gap: 16px;

      .profile-img {
        border: 1px solid #e0e0e7;
        width: 90px;
        height: 90px;
        border-radius: 40px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }

      .profile-card-box {
        .label {
          display: block;
          padding: 0.2rem 0.5rem;
          font-size: 12px;
          font-weight: bold;
          display: inline-block;
          border-radius: 6px;

          &.public {
            color: #5658b7;
            background-color: rgba(86, 88, 183, 0.2);
          }

          &.vip {
            color: #ff7b7b;
            background-color: rgba(255, 123, 123, 0.2);
          }
        }

        .name {
          font-size: 20px;
          font-weight: bold;
          color: #161616;
        }

        .email-id {
          color: #6f6f6f;
          font-weight: 500;
          font-size: 16px;
        }
      }
    }

    .form-box {
      .info-box {
        border: 1px solid #f1f1f8;
        width: 100%;

        .info-box-grid {
          display: grid;
          grid-template-columns: 125px 1fr;
          align-items: center;
          flex: 1;

          .grid-title {
            padding: 0.5rem 0.5rem;
            background-color: #fafafd;
            border-bottom: 1px solid #f1f1f8;
            border-right: 1px solid #f1f1f8;
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 400;
            color: #525252;
            flex: 120px 0 0;
          }

          .grid-body {
            padding: 0.5rem 0.5rem;
            border-bottom: 1px solid #f1f1f8;
            font-size: 16px;
            font-weight: 500;
            color: #161616;
            line-height: unset;
            word-break: break-all;
          }
        }

        .d-flex {
          gap: 0;
          align-items: unset;
        }
      }
    }

    table {
      align-self: stretch;
      border-top: 1px solid var(--Weak-Gray, #f1f1f8);

      th {
        background: var(--Pale-Gray, #fafafd);
        color: var(--text-secondary, #525252);
        font-family: 'Pretendard';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.16px;
        text-align: left;
      }

      .th-center {
        text-align: center;
      }

      .th-right {
        text-align: right;
      }

      .th-left {
        text-align: left;
      }

      td {
        text-align: left;

        .table-tr {
          background-color: #fafafd;
          padding: 8px 71px 8px 48px;
          align-items: center;
          gap: 16px;
          align-self: stretch;
        }

        button {
          border: 1px solid var(--Dark-Blue, #5658b7);
          border-radius: 6px;
          padding: 7px;
          height: 42px;
          width: 75px;
          font-size: 14px;
        }
      }

      .state-label {
        font-weight: 600;
        font-size: 16px;
        text-align: center;

        &.sucess {
          color: #42be65;
        }

        &.cancel {
          color: #da1e28;
        }

        &.end {
          color: #9b9b9b;
        }
      }

      .td-text-title {
        width: 130px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
      }

      .td-text-detail {
        width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
      }
    }
  }

  .menu-article {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-end;
    align-self: stretch;
    gap: 16px;
    border: 1px solid var(--border-border-02, #c6c6c6);
    border-radius: 14px;
    background: #fff;

    .table-top-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      h6 {
        margin-bottom: 0;
        font-weight: 600;
        font-size: 20px;
      }

      .table-top-text {
        color: #161616;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0;
      }
    }

    table {
      align-self: stretch;
      border-top: 1px solid var(--Weak-Gray, #f1f1f8);

      th {
        background: var(--Pale-Gray, #fafafd);
        color: var(--text-secondary, #525252);
        font-family: 'Pretendard';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.16px;
        text-align: left;
      }

      .th-center {
        text-align: center;
      }

      .th-right {
        text-align: right;
      }

      .th-left {
        text-align: left;
      }

      td {
        text-align: left;

        .table-tr {
          background-color: #fafafd;
          padding: 8px 71px 8px 48px;
          align-items: center;
          gap: 16px;
          align-self: stretch;
        }

        button {
          border: 1px solid var(--Dark-Blue, #5658b7);
          border-radius: 6px;
          padding: 7px;
          height: 42px;
          width: 75px;
          font-size: 14px;
        }
      }

      .state-label {
        font-weight: 600;
        font-size: 16px;
        text-align: center;

        &.sucess {
          color: #42be65;
        }

        &.cancel {
          color: #da1e28;
        }

        &.end {
          color: #9b9b9b;
        }
      }

      .td-text-title {
        width: 130px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
      }

      .td-text-detail {
        width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
      }
    }

    p {
      color: var(--text-primary, #161616);
      font-family: 'Pretendard';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
      margin-bottom: 8px;
    }

    input {
      border-radius: 6px;
      border: 1px solid var(--border-border-01, #e0e0e0);
      width: 100%;
      height: 48px;
      padding: 15px 16px;
    }

    .article-text {
      color: var(--Black, #000);
      font-family: 'Pretendard';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.16px;
    }
  }
}

//공지사항
#notice {
  background-color: #fafafd;

  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: center;
    align-self: stretch;

    .title-text {
      color: #000000;
      font-family: 'Pretendard';
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.16px;
    }
  }

  .menu-top-article {
    display: flex;
    padding: 16px;
    flex-direction: column;
    gap: 16px;
    border: 1px solid var(--border-border-02, #c6c6c6);
    border-radius: 14px;
    background: #fff;
    margin-bottom: 16px;

    .grid-top-box {
      display: grid;
      grid-template-columns: 100px 1fr;
      align-items: center;

      .form-control {
        width: 288px;
      }
    }

    .register {
      border-radius: 6px;
      background-color: #387fff;
      color: #fff;
      width: 57px;
      height: 48px;
    }
  }

  .menu-article {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-end;
    align-self: stretch;
    gap: 16px;
    border: 1px solid var(--border-border-02, #c6c6c6);
    border-radius: 14px;
    background: #fff;

    table {
      align-self: stretch;
      border-top: 1px solid var(--Weak-Gray, #f1f1f8);

      th {
        background: var(--Pale-Gray, #fafafd);
        color: var(--text-secondary, #525252);
        font-family: 'Pretendard';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.16px;
        text-align: left;
      }

      .th-center {
        text-align: center;
      }

      .th-right {
        text-align: right;
      }

      .th-left {
        text-align: left;
      }

      td {
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        .table-tr {
          background-color: #fafafd;
          padding: 8px 71px 8px 48px;
          align-items: center;
          gap: 16px;
          align-self: stretch;
        }

        button {
          border: 1px solid var(--Dark-Blue, #5658b7);
          border-radius: 6px;
          padding: 7px;
          height: 42px;
          width: 75px;
          font-size: 14px;
        }
      }

      .state-label {
        font-weight: 600;
        font-size: 16px;
        text-align: center;

        &.sucess {
          color: #42be65;
        }

        &.cancel {
          color: #da1e28;
        }

        &.end {
          color: #9b9b9b;
        }
      }

      .td-text-title {
        width: 130px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
      }

      .td-text-detail {
        width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
      }
    }

    p {
      color: var(--text-primary, #161616);
      font-family: 'Pretendard';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
      margin-bottom: 8px;
    }

    input {
      border-radius: 6px;
      border: 1px solid var(--border-border-01, #e0e0e0);
      width: 100%;
      height: 48px;
      padding: 15px 16px;
    }

    .article-text {
      color: var(--Black, #000);
      font-family: 'Pretendard';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.16px;
    }
  }
}

//공지 디테일
#notice-detail {
  width: auto;
  background: var(--Pale-Gray, #fafafd);

  .title {
    display: flex;
    justify-content: space-between;
    margin: 24px 0;
    align-items: center;
    align-self: stretch;

    .title-text {
      color: #000000;
      font-family: 'Pretendard';
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.16px;
    }
  }

  .report-info-title {
    color: #161616;
    font-weight: 500;
    margin-bottom: 0;
  }

  .partner-detail-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: auto;
    height: auto;
    padding: 16px;
    gap: 16px;
    margin: 24px 0px 32px 0px;
    border-radius: 14px;
    border: 1px solid var(--border-border-02, #c6c6c6);
    background: #fff;

    .title-area {
      border-bottom: 0;

      h2 {
        font-weight: 700;
        font-style: normal;
        font-family: Pretendard;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; /* 155.556% */
        letter-spacing: 0.16px;
        margin: 0;
        color: #161616;
      }
    }

    .input-area {
      width: 100%;
    }

    .btn-area {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      align-self: stretch;
    }

    .form-box {
      .info-box {
        border: 1px solid #f1f1f8;
        width: 100%;

        .info-box-grid {
          display: grid;
          grid-template-columns: 125px 1fr;
          align-items: center;

          .grid-title {
            padding: 1rem 0.5rem;
            background-color: #fafafd;
            border-bottom: 1px solid #f1f1f8;
            border-right: 1px solid #f1f1f8;
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 400;
            color: #525252;
          }

          .grid-body {
            padding: 1rem 0.5rem;
            border-bottom: 1px solid #f1f1f8;
            font-size: 16px;
            font-weight: 500;
            color: #161616;
            line-height: unset;
            white-space: pre-line;
          }

          .state-label {
            padding: 1rem 0.5rem;
            border-bottom: 1px solid #f1f1f8;
            font-size: 16px;
            font-weight: 500;
            color: #161616;
            line-height: unset;

            &.prog {
              color: #42be65;
            }

            &.stay {
              color: #326bff;
            }

            &.end {
              color: #9b9b9b;
            }
          }

          .grid-img-body {
            padding: 1rem 0.5rem;
            border-bottom: 1px solid #f1f1f8;
            display: flex;
            align-items: center;
            gap: 8px;

            .banner-img {
              width: 328px;
              height: 82px;
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
              border-radius: 10px;
            }
          }
        }
      }
    }
  }
}

//공지 등록
#notice-Register {
  background-color: #fafafd;

  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: center;
    align-self: stretch;

    .title-text {
      color: #000000;
      font-family: 'Pretendard';
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.16px;
    }

    button {
      border: 1px solid var(--Dark-Blue, #5658b7);
      border-radius: 6px;
      padding: 14px 16px;
      color: var(--text-primary, #161616);
      font-family: 'Pretendard';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
    }
  }

  .menu-article {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-end;
    align-self: stretch;
    gap: 16px;
    border: 1px solid var(--border-border-02, #c6c6c6);
    border-radius: 14px;
    background: #fff;

    .align-left {
      align-self: flex-start;
      width: -webkit-fill-available;

      .add-div {
        margin-bottom: 16px;

        .form-control {
          border-radius: 6px;
          width: 100%;
        }
      }
    }

    p {
      color: var(--text-primary, #161616);
      font-family: 'Pretendard';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
      margin-bottom: 8px;
    }

    .article-text {
      color: var(--Black, #000);
      font-family: 'Pretendard';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.16px;
    }

    .register {
      border-radius: 6px;
      background-color: #387fff;
      color: #fff;
      width: 57px;
      height: 48px;
    }

    .close-img {
      margin: 0 5px;
      width: 25px;
      height: 25px;
      cursor: pointer;
      align-self: center;
    }

    .cancle-btn {
      border-radius: 6px;
      border: 1px solid #387fff;
      width: 57px;
      height: 48px;
      margin-right: 8px;
    }

    .image-input {
      border: 1px solid var(--Weak-Gray, #f1f1f8);
      display: flex;
      width: 328px;
      height: 82px;
      padding: 21px 18px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;

      label {
        display: contents;
      }

      span {
        color: var(--text-helper, #6f6f6f);
        font-family: 'Pretendard';
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        // letter-spacing: 0.32px;
      }

      input[type='file'] {
        position: absolute;
        width: 0;
        height: 0;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
      }
    }

    .helper-text {
      color: var(--text-secondary, #525252);
      font-family: 'Pretendard';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.32px;
      margin-top: 8px;
    }

    .upload-css {
      width: 328px;
      height: 82px;
      position: relative;
      margin-bottom: 0 !important;

      .close-img {
        width: 7px;
        height: 7px;
        position: absolute;
        top: 0;
        right: 0;
      }

      .select-img {
        width: 328px;
        height: 82px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}

//치료후기
#review {
  background-color: #fafafd;

  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: center;
    align-self: stretch;

    .title-text {
      color: #000000;
      font-family: 'Pretendard';
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.16px;
    }
  }

  .menu-top-article {
    display: flex;
    padding: 16px;
    flex-direction: column;
    gap: 16px;
    border: 1px solid var(--border-border-02, #c6c6c6);
    border-radius: 14px;
    background: #fff;
    margin-bottom: 16px;

    .grid-top-box {
      display: grid;
      grid-template-columns: 100px 1fr;
      align-items: center;

      .form-control {
        width: 288px;
      }
    }

    .register {
      border-radius: 6px;
      background-color: #387fff;
      color: #fff;
      width: 57px;
      height: 48px;
    }
  }

  .menu-article {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-end;
    align-self: stretch;
    gap: 16px;
    border: 1px solid var(--border-border-02, #c6c6c6);
    border-radius: 14px;
    background: #fff;

    table {
      align-self: stretch;
      border-top: 1px solid var(--Weak-Gray, #f1f1f8);

      th {
        background: var(--Pale-Gray, #fafafd);
        color: var(--text-secondary, #525252);
        font-family: 'Pretendard';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.16px;
        text-align: left;
      }

      .th-center {
        text-align: center;
      }

      .th-right {
        text-align: right;
      }

      .th-left {
        text-align: left;
      }

      td {
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        .table-tr {
          background-color: #fafafd;
          padding: 8px 71px 8px 48px;
          align-items: center;
          gap: 16px;
          align-self: stretch;
        }

        button {
          border: 1px solid var(--Dark-Blue, #5658b7);
          border-radius: 6px;
          padding: 7px;
          height: 42px;
          width: 75px;
          font-size: 14px;
        }
      }

      .state-label {
        font-weight: 600;
        font-size: 16px;
        text-align: center;

        &.sucess {
          color: #42be65;
        }

        &.cancel {
          color: #da1e28;
        }

        &.end {
          color: #9b9b9b;
        }
      }

      .td-text-title {
        width: 130px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
      }

      .td-text-detail {
        width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
      }
    }

    p {
      color: var(--text-primary, #161616);
      font-family: 'Pretendard';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
      margin-bottom: 8px;
    }

    input {
      border-radius: 6px;
      border: 1px solid var(--border-border-01, #e0e0e0);
      width: 100%;
      height: 48px;
      padding: 15px 16px;
    }

    .article-text {
      color: var(--Black, #000);
      font-family: 'Pretendard';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.16px;
    }
  }
}

//치료후기 디테일
#review-detail {
  width: auto;
  background: var(--Pale-Gray, #fafafd);

  .title {
    display: flex;
    justify-content: space-between;
    margin: 24px 0;
    align-items: center;
    align-self: stretch;

    .title-text {
      color: #000000;
      font-family: 'Pretendard';
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.16px;
    }
  }

  .report-info-title {
    color: #161616;
    font-weight: 500;
    margin-bottom: 0;
  }

  .partner-detail-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: auto;
    height: auto;
    padding: 16px;
    gap: 16px;
    margin: 24px 0px 32px 0px;
    border-radius: 14px;
    border: 1px solid var(--border-border-02, #c6c6c6);
    background: #fff;

    .title-area {
      border-bottom: 0;

      h2 {
        font-weight: 700;
        font-style: normal;
        font-family: Pretendard;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; /* 155.556% */
        letter-spacing: 0.16px;
        margin: 0;
        color: #161616;
      }
    }

    .input-area {
      width: 100%;
    }

    .btn-area {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      align-self: stretch;
    }

    .form-box {
      .info-box {
        border: 1px solid #f1f1f8;
        width: 100%;

        .info-box-grid {
          display: grid;
          grid-template-columns: 125px 1fr;
          align-items: center;

          .grid-title {
            padding: 1rem 0.5rem;
            background-color: #fafafd;
            border-bottom: 1px solid #f1f1f8;
            border-right: 1px solid #f1f1f8;
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 400;
            color: #525252;
          }

          .grid-body {
            padding: 1rem 0.5rem;
            border-bottom: 1px solid #f1f1f8;
            font-size: 16px;
            font-weight: 500;
            color: #161616;
            line-height: unset;
            white-space: pre-line;
          }

          .state-label {
            padding: 1rem 0.5rem;
            border-bottom: 1px solid #f1f1f8;
            font-size: 16px;
            font-weight: 500;
            color: #161616;
            line-height: unset;

            &.prog {
              color: #42be65;
            }

            &.stay {
              color: #326bff;
            }

            &.end {
              color: #9b9b9b;
            }
          }

          .grid-img-body {
            padding: 1rem 0.5rem;
            border-bottom: 1px solid #f1f1f8;
            display: flex;
            align-items: center;
            gap: 8px;

            .banner-img {
              width: 328px;
              height: 82px;
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
              border-radius: 10px;
            }
          }
        }
      }
    }
  }
}

//치료후기 등록
#review-Register {
  background-color: #fafafd;

  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: center;
    align-self: stretch;

    .title-text {
      color: #000000;
      font-family: 'Pretendard';
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.16px;
    }

    button {
      border: 1px solid var(--Dark-Blue, #5658b7);
      border-radius: 6px;
      padding: 14px 16px;
      color: var(--text-primary, #161616);
      font-family: 'Pretendard';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
    }
  }

  .menu-article {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-end;
    align-self: stretch;
    gap: 16px;
    border: 1px solid var(--border-border-02, #c6c6c6);
    border-radius: 14px;
    background: #fff;

    .align-left {
      align-self: flex-start;
      width: -webkit-fill-available;

      .add-div {
        margin-bottom: 16px;

        .form-control {
          border-radius: 6px;
          width: 100%;
        }
      }
    }

    p {
      color: var(--text-primary, #161616);
      font-family: 'Pretendard';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
      margin-bottom: 8px;
    }

    .article-text {
      color: var(--Black, #000);
      font-family: 'Pretendard';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.16px;
    }

    .register {
      border-radius: 6px;
      background-color: #387fff;
      color: #fff;
      width: 57px;
      height: 48px;
    }

    .close-img {
      margin: 0 5px;
      width: 25px;
      height: 25px;
      cursor: pointer;
      align-self: center;
    }

    .cancle-btn {
      border-radius: 6px;
      border: 1px solid #387fff;
      width: 57px;
      height: 48px;
      margin-right: 8px;
    }

    .image-input {
      border: 1px solid var(--Weak-Gray, #f1f1f8);
      display: flex;
      width: 90px;
      height: 90px;
      padding: 21px 18px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;

      label {
        display: contents;
      }

      span {
        color: var(--text-helper, #6f6f6f);
        font-family: 'Pretendard';
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        // letter-spacing: 0.32px;
      }

      input[type='file'] {
        position: absolute;
        width: 0;
        height: 0;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
      }
    }

    .helper-text {
      color: var(--text-secondary, #525252);
      font-family: 'Pretendard';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.32px;
      margin-top: 8px;
    }

    .register {
      border-radius: 6px;
      background-color: var(--Dark-Blue, #5658b7);
      color: #fff;
      width: 57px;
      height: 48px;
    }

    .cancle-btn {
      border-radius: 6px;
      border: 1px solid var(--Dark-Blue, #5658b7);
      width: 57px;
      height: 48px;
      margin-right: 8px;
    }

    .upload-css {
      width: 90px;
      height: 90px;
      position: relative;
      margin-bottom: 0 !important;

      .close-img {
        width: 14px;
        height: 14px;
        position: absolute;
        top: 0;
        right: -4px;
      }

      .select-img {
        width: 90px;
        height: 90px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}

//코드 관리
#codeset {
  background-color: #fafafd;

  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: center;
    align-self: stretch;

    .title-text {
      color: #000000;
      font-family: 'Pretendard';
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.16px;
    }

    Button {
      border: 1px solid var(--Dark-Blue, #5658b7);
      border-radius: 6px;
      padding: 14px 16px;
      color: #ffffff;
      font-family: 'Pretendard';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
    }
  }

  .menu-top-article {
    display: flex;
    padding: 16px;
    flex-direction: column;
    gap: 16px;
    border: 1px solid var(--border-border-02, #c6c6c6);
    border-radius: 14px;
    background: #fff;
    margin-bottom: 16px;

    .grid-top-box {
      display: grid;
      grid-template-columns: 100px 1fr;
      align-items: center;
    }

    .register {
      border-radius: 6px;
      background-color: var(--Dark-Blue, #5658b7);
      color: #fff;
      width: 57px;
      height: 48px;
    }
  }

  .menu-article {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-end;
    align-self: stretch;
    gap: 16px;
    border: 1px solid var(--border-border-02, #c6c6c6);
    border-radius: 14px;
    background: #fff;
    width: 100%;

    .add-btn-title {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;

      h6 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0;
      }

      Button {
        background-color: rgba(56, 127, 255, 0.1);
        border: none;

        .material-icons {
          color: #387fff;
          font-weight: bold;
        }
      }
    }

    table {
      align-self: stretch;
      border-top: 1px solid var(--Weak-Gray, #f1f1f8);
      table-layout: auto;

      th {
        background: var(--Pale-Gray, #fafafd);
        color: var(--text-secondary, #525252);
        font-family: 'Pretendard';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.16px;
        text-align: left;
      }

      .th-center {
        text-align: center;
      }

      .th-right {
        text-align: right;
      }

      .th-left {
        text-align: left;
      }

      .td-btn-box {
        margin-top: 8px;

        Button {
          width: 35px;
          height: 32px;
          padding: 0;
        }
      }

      td {
        text-align: left;
        word-break: break-all;

        .table-tr {
          background-color: #fafafd;
          padding: 8px 71px 8px 48px;
          align-items: center;
          gap: 16px;
          align-self: stretch;
        }

        .edit-btn {
          background-color: rgba(56, 127, 255, 0.1);
          width: 42px;
          color: #387fff;
          height: 35px;
          border: none;
          font-size: 12px;
          padding: 0;
        }
      }

      .state-label {
        font-weight: 600;
        font-size: 16px;
        text-align: center;

        &.request {
          color: #1192e8;
        }

        &.sucess {
          color: #42be65;
        }

        &.cancel {
          color: #ff7b7b;
        }

        &.end {
          color: #9b9b9b;
        }
      }

      .td-text-title {
        width: 130px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
      }

      .td-text-detail {
        width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
      }
    }

    p {
      color: var(--text-primary, #161616);
      font-family: 'Pretendard';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
      margin-bottom: 8px;
    }

    .article-text {
      color: var(--Black, #000);
      font-family: 'Pretendard';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.16px;
    }
  }
}

.customSelect {
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 0.375rem;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  padding: 0;
  border: 1px solid var(--bs-border) !important;
  padding: 0.6rem 0.8rem;
  position: relative;
  display: inline-block;
}

//배너
#banner {
  background-color: #fafafd;

  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: center;
    align-self: stretch;

    .title-text {
      color: #000000;
      font-family: 'Pretendard';
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.16px;
    }

    button {
      border: 1px solid var(--Dark-Blue, #5658b7);
      border-radius: 6px;
      padding: 14px 16px;
      color: var(--text-primary, #161616);
      font-family: 'Pretendard';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;

      &:disabled {
        pointer-events: none;
        opacity: 1;
        color: var(--bs-btn-disabled-color, #35403a);
        background-color: #b1b3c7;
        border-color: #b1b3c7;
      }
    }
  }

  .banner-article {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-end;
    align-self: stretch;
    gap: 16px;
    border: 1px solid var(--border-border-02, #c6c6c6);
    border-radius: 14px;
    background: #fff;

    table {
      align-self: stretch;
      border-top: 1px solid var(--Weak-Gray, #f1f1f8);

      th {
        background: var(--Pale-Gray, #fafafd);
        color: var(--text-secondary, #525252);
        font-family: 'Pretendard';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.16px;
        text-align: left;
      }

      .th-center {
        text-align: center;
      }

      .th-right {
        text-align: right;
      }

      .popup-image {
        width: 80px;
        height: 55px;
      }

      td {
        text-align: left;

        .table-tr {
          background-color: #fafafd;
          padding: 8px 71px 8px 48px;
          align-items: center;
          gap: 16px;
          align-self: stretch;
        }

        button {
          border: 1px solid var(--Dark-Blue, #5658b7);
          border-radius: 6px;
          padding: 11px;
        }
      }

      .col-draghandle {
        width: 48px;
      }

      .col-popupNm {
        width: 298px;
      }

      .col-popupCost {
        width: 90px;
      }

      .col-popupInfo {
        width: 365px;
      }

      .col-popupView {
        width: 40px;
      }

      .col-popupModi {
        width: 71px;
      }

      .td-center {
        text-align: center;
      }

      .popupImg {
        margin-right: 8px;
        width: 60px;
        height: 60px;
      }
    }

    .align-left {
      align-self: flex-start;
      width: -webkit-fill-available;

      div {
        margin-bottom: 16px;

        .photo-imgbox {
          width: 90px;
          height: 90px;
          position: relative;
          margin-bottom: 0;

          .photo-img {
            width: 90px;
            height: 90px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
          }

          .photo-del {
            background-position: center;
            width: 14px;
            height: 14px;
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            right: 0px;
          }
        }

        .pic-btn {
          border: 1px solid #f1f1f8;
          border-radius: 0;
          width: 90px;
          height: 90px;
          display: flex;
          padding: 21px 18px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 8px;
          color: #6f6f6f;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0.32px;
          background-color: #fff;
          text-wrap: nowrap;
        }
      }
    }

    p {
      color: var(--text-primary, #161616);
      font-family: 'Pretendard';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
      margin-bottom: 8px;
    }

    input {
      border-radius: 6px;
      border: 1px solid var(--border-border-01, #e0e0e0);
      width: 100%;
      height: 48px;
      padding: 15px 16px;
    }

    .article-text {
      color: var(--Black, #000);
      font-family: 'Pretendard';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.16px;
    }

    .image-input {
      border: 1px solid var(--Weak-Gray, #f1f1f8);
      display: flex;
      width: 90px;
      height: 90px;
      padding: 21px 18px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;

      label {
        display: contents;
      }

      span {
        color: var(--text-helper, #6f6f6f);
        font-family: 'Pretendard';
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        // letter-spacing: 0.32px;
      }

      input[type='file'] {
        position: absolute;
        width: 0;
        height: 0;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
      }
    }

    .helper-text {
      color: var(--text-secondary, #525252);
      font-family: 'Pretendard';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.32px;
      margin-top: 8px;
    }

    .register {
      border-radius: 6px;
      background-color: var(--Dark-Blue, #5658b7);
      color: #fff;
      width: 57px;
      height: 48px;
    }

    .cancle-btn {
      border-radius: 6px;
      border: 1px solid var(--Dark-Blue, #5658b7);
      width: 57px;
      height: 48px;
      margin-right: 8px;
    }

    .upload-css {
      width: 90px;
      height: 90px;
      position: relative;
      margin-bottom: 0 !important;

      .close-img {
        width: 14px;
        height: 14px;
        position: absolute;
        top: 0;
        right: 0;
      }

      .select-img {
        width: 90px;
        height: 90px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}

//팝업
#popup {
  background-color: #fafafd;

  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: center;
    align-self: stretch;

    .title-text {
      color: #000000;
      font-family: 'Pretendard';
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.16px;
    }

    button {
      border: 1px solid var(--Dark-Blue, #5658b7);
      border-radius: 6px;
      padding: 14px 16px;
      color: var(--text-primary, #161616);
      font-family: 'Pretendard';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;

      &:disabled {
        pointer-events: none;
        opacity: 1;
        color: var(--bs-btn-disabled-color, #35403a);
        background-color: #b1b3c7;
        border-color: #b1b3c7;
      }
    }
  }

  .popup-article {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-end;
    align-self: stretch;
    gap: 16px;
    border: 1px solid var(--border-border-02, #c6c6c6);
    border-radius: 14px;
    background: #fff;

    table {
      align-self: stretch;
      border-top: 1px solid var(--Weak-Gray, #f1f1f8);

      th {
        background: var(--Pale-Gray, #fafafd);
        color: var(--text-secondary, #525252);
        font-family: 'Pretendard';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.16px;
        text-align: left;
      }

      .th-center {
        text-align: center;
      }

      .th-right {
        text-align: right;
      }

      .popup-image {
        width: 80px;
        height: 55px;
      }

      td {
        text-align: left;

        .table-tr {
          background-color: #fafafd;
          padding: 8px 71px 8px 48px;
          align-items: center;
          gap: 16px;
          align-self: stretch;
        }

        button {
          border: 1px solid var(--Dark-Blue, #5658b7);
          border-radius: 6px;
          padding: 11px;
        }
      }

      .col-draghandle {
        width: 48px;
      }

      .col-popupNm {
        width: 298px;
      }

      .col-popupCost {
        width: 90px;
      }

      .col-popupInfo {
        width: 365px;
      }

      .col-popupView {
        width: 40px;
      }

      .col-popupModi {
        width: 71px;
      }

      .td-center {
        text-align: center;
      }

      .popupImg {
        margin-right: 8px;
        width: 60px;
        height: 60px;
      }
    }

    .align-left {
      align-self: flex-start;
      width: -webkit-fill-available;

      .add-div {
        margin-bottom: 16px;

        .photo-imgbox {
          width: 90px;
          height: 90px;
          position: relative;
          margin-bottom: 0;

          .photo-img {
            width: 90px;
            height: 90px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
          }

          .photo-del {
            background-position: center;
            width: 14px;
            height: 14px;
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            right: 0px;
          }
        }

        .pic-btn {
          border: 1px solid #f1f1f8;
          border-radius: 0;
          width: 90px;
          height: 90px;
          display: flex;
          padding: 21px 18px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 8px;
          color: #6f6f6f;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0.32px;
          background-color: #fff;
          text-wrap: nowrap;
        }
      }
    }

    p {
      color: var(--text-primary, #161616);
      font-family: 'Pretendard';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
      margin-bottom: 8px;
    }

    input {
      border-radius: 6px;
      border: 1px solid var(--border-border-01, #e0e0e0);
      width: 100%;
      height: 48px;
      padding: 15px 16px;
    }

    .article-text {
      color: var(--Black, #000);
      font-family: 'Pretendard';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.16px;
    }

    .image-input {
      border: 1px solid var(--Weak-Gray, #f1f1f8);
      display: flex;
      width: 90px;
      height: 90px;
      padding: 21px 18px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;

      label {
        display: contents;
      }

      span {
        color: var(--text-helper, #6f6f6f);
        font-family: 'Pretendard';
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        // letter-spacing: 0.32px;
      }

      input[type='file'] {
        position: absolute;
        width: 0;
        height: 0;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
      }
    }

    .helper-text {
      color: var(--text-secondary, #525252);
      font-family: 'Pretendard';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.32px;
      margin-top: 8px;
    }

    .register {
      border-radius: 6px;
      background-color: var(--Dark-Blue, #5658b7);
      color: #fff;
      width: 57px;
      height: 48px;
    }

    .cancle-btn {
      border-radius: 6px;
      border: 1px solid var(--Dark-Blue, #5658b7);
      width: 57px;
      height: 48px;
      margin-right: 8px;
    }

    .upload-css {
      width: 90px;
      height: 90px;
      position: relative;
      margin-bottom: 0 !important;

      .close-img {
        width: 14px;
        height: 14px;
        position: absolute;
        top: 0;
        right: 0;
      }

      .select-img {
        width: 90px;
        height: 90px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}

//어드민,사용자 로그
#syslog {
  background-color: #fafafd;

  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: center;
    align-self: stretch;

    .title-text {
      color: #000000;
      font-family: 'Pretendard';
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.16px;
    }

    button {
      border: 1px solid var(--Dark-Blue, #5658b7);
      border-radius: 6px;
      padding: 14px 16px;
      color: var(--text-primary, #161616);
      font-family: 'Pretendard';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
    }
  }

  .menu-top-article {
    display: flex;
    padding: 16px;
    flex-direction: column;
    gap: 16px;
    border: 1px solid var(--border-border-02, #c6c6c6);
    border-radius: 14px;
    background: #fff;
    margin-bottom: 16px;

    .grid-top-box {
      display: grid;
      grid-template-columns: 100px 1fr;
      align-items: center;
    }

    .register {
      border-radius: 6px;
      background-color: var(--Dark-Blue, #5658b7);
      color: #fff;
      width: 57px;
      height: 48px;
    }
  }

  .menu-article {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-end;
    align-self: stretch;
    gap: 16px;
    border: 1px solid var(--border-border-02, #c6c6c6);
    border-radius: 14px;
    background: #fff;

    table {
      align-self: stretch;
      border-top: 1px solid var(--Weak-Gray, #f1f1f8);

      th {
        background: var(--Pale-Gray, #fafafd);
        color: var(--text-secondary, #525252);
        font-family: 'Pretendard';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.16px;
        text-align: left;
      }

      .th-center {
        text-align: center;
      }

      .th-right {
        text-align: right;
      }

      .th-left {
        text-align: left;
      }

      td {
        text-align: left;

        .table-tr {
          background-color: #fafafd;
          padding: 8px 71px 8px 48px;
          align-items: center;
          gap: 16px;
          align-self: stretch;
        }

        button {
          border: 1px solid var(--Dark-Blue, #5658b7);
          border-radius: 6px;
          padding: 7px;
          height: 42px;
          width: 75px;
          font-size: 14px;
        }
      }

      .state-label {
        font-weight: 600;
        font-size: 16px;
        text-align: center;

        &.sucess {
          color: #42be65;
        }

        &.cancel {
          color: #da1e28;
        }

        &.end {
          color: #9b9b9b;
        }
      }

      .td-text-title {
        width: 130px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
      }

      .td-text-detail {
        width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
      }
    }

    p {
      color: var(--text-primary, #161616);
      font-family: 'Pretendard';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
      margin-bottom: 8px;
    }

    input {
      border-radius: 6px;
      border: 1px solid var(--border-border-01, #e0e0e0);
      width: 100%;
      height: 48px;
      padding: 15px 16px;
    }

    .article-text {
      color: var(--Black, #000);
      font-family: 'Pretendard';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.16px;
    }
  }
}

//약관 관리
#sys-terms {
  background-color: #fafafd;

  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: center;
    align-self: stretch;

    .title-text {
      color: #000000;
      font-family: 'Pretendard';
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.16px;
    }
  }

  .menu-top-article {
    display: flex;
    padding: 16px;
    flex-direction: column;
    gap: 16px;
    border: 1px solid var(--border-border-02, #c6c6c6);
    border-radius: 14px;
    background: #fff;
    margin-bottom: 16px;

    .grid-top-box {
      display: grid;
      grid-template-columns: 100px 1fr;
      align-items: center;
    }

    .register {
      border-radius: 6px;
      background-color: var(--Dark-Blue, #5658b7);
      color: #fff;
      width: 57px;
      height: 48px;
    }
  }

  .menu-article {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-end;
    align-self: stretch;
    gap: 16px;
    border: 1px solid var(--border-border-02, #c6c6c6);
    border-radius: 14px;
    background: #fff;

    table {
      align-self: stretch;
      border-top: 1px solid var(--Weak-Gray, #f1f1f8);

      th {
        background: var(--Pale-Gray, #fafafd);
        color: var(--text-secondary, #525252);
        font-family: 'Pretendard';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.16px;
        text-align: left;
      }

      .th-center {
        text-align: center;
      }

      .th-right {
        text-align: right;
      }

      .th-left {
        text-align: left;
      }

      td {
        text-align: left;

        .table-tr {
          background-color: #fafafd;
          padding: 8px 71px 8px 48px;
          align-items: center;
          gap: 16px;
          align-self: stretch;
        }

        button {
          border: 1px solid var(--Dark-Blue, #5658b7);
          border-radius: 6px;
          padding: 7px;
          height: 42px;
          width: 75px;
          font-size: 14px;
        }
      }

      .state-label {
        font-weight: 600;
        font-size: 16px;
        text-align: center;

        &.sucess {
          color: #42be65;
        }

        &.cancel {
          color: #da1e28;
        }

        &.end {
          color: #9b9b9b;
        }
      }

      .td-text-title {
        width: 130px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
      }

      .td-text-detail {
        width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
      }
    }

    p {
      color: var(--text-primary, #161616);
      font-family: 'Pretendard';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
      margin-bottom: 8px;
    }

    input {
      border-radius: 6px;
      border: 1px solid var(--border-border-01, #e0e0e0);
      width: 100%;
      height: 48px;
      padding: 15px 16px;
    }

    .article-text {
      color: var(--Black, #000);
      font-family: 'Pretendard';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.16px;
    }
  }
}

#sys-Register {
  background-color: #fafafd;

  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: center;
    align-self: stretch;

    .title-text {
      color: #000000;
      font-family: 'Pretendard';
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.16px;
    }

    button {
      border: 1px solid var(--Dark-Blue, #5658b7);
      border-radius: 6px;
      padding: 14px 16px;
      color: var(--text-primary, #161616);
      font-family: 'Pretendard';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
    }
  }

  .menu-article {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-end;
    align-self: stretch;
    gap: 16px;
    border: 1px solid var(--border-border-02, #c6c6c6);
    border-radius: 14px;
    background: #fff;

    .align-left {
      align-self: flex-start;
      width: -webkit-fill-available;

      .add-div {
        margin-bottom: 16px;

        .form-control {
          border-radius: 6px;
          width: 100%;
        }
      }
    }

    p {
      color: var(--text-primary, #161616);
      font-family: 'Pretendard';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
      margin-bottom: 8px;
    }

    .article-text {
      color: var(--Black, #000);
      font-family: 'Pretendard';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.16px;
    }

    .register {
      border-radius: 6px;
      background-color: var(--Dark-Blue, #5658b7);
      color: #fff;
      width: 57px;
      height: 48px;
    }

    .cancle-btn {
      border-radius: 6px;
      border: 1px solid var(--Dark-Blue, #5658b7);
      width: 57px;
      height: 48px;
      margin-right: 8px;
    }

    .image-input {
      border: 1px solid var(--Weak-Gray, #f1f1f8);
      display: flex;
      width: 328px;
      height: 82px;
      padding: 21px 18px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;

      label {
        display: contents;
      }

      span {
        color: var(--text-helper, #6f6f6f);
        font-family: 'Pretendard';
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        // letter-spacing: 0.32px;
      }

      input[type='file'] {
        position: absolute;
        width: 0;
        height: 0;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
      }
    }

    .helper-text {
      color: var(--text-secondary, #525252);
      font-family: 'Pretendard';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.32px;
      margin-top: 8px;
    }

    .upload-css {
      width: auto;
      height: 82px;
      position: relative;
      margin-bottom: 0 !important;

      .close-img {
        width: 14px;
        height: 14px;
        position: absolute;
        top: 0;
        right: 0;
      }

      .select-img {
        width: 328px;
        height: 82px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}

#sys-menu-permission {
  background-color: #fafafd;

  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: center;
    align-self: stretch;

    .title-text {
      color: #000000;
      font-family: 'Pretendard';
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.16px;
    }

    button {
      border: 1px solid var(--Dark-Blue, #5658b7);
      border-radius: 6px;
      padding: 14px 16px;
      color: var(--text-primary, #161616);
      font-family: 'Pretendard';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
    }
  }

  .menu-article {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-end;
    align-self: stretch;
    gap: 16px;
    border: 1px solid var(--border-border-02, #c6c6c6);
    border-radius: 14px;
    background: #fff;

    .tab-menu {
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 1fr;
      border-bottom: 1px solid #c6c6c6;
      width: 100%;

      Button {
        width: 100%;
        border: none;
        height: 40px;
        background-color: transparent;
        font-weight: 400;
        color: #080707;
        border-radius: 0;

        &.active {
          border-bottom: 2px solid #5658b7;
          font-weight: bold;
        }
      }
    }

    table {
      align-self: stretch;
      border-top: 1px solid var(--Weak-Gray, #f1f1f8);

      th {
        background: var(--Pale-Gray, #fafafd);
        color: var(--text-secondary, #525252);
        font-family: 'Pretendard';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.16px;
        text-align: left;
      }

      .th-center {
        text-align: center;
      }

      .th-right {
        text-align: right;
      }

      td {
        text-align: left;

        .table-tr {
          background-color: #fafafd;
          padding: 8px 71px 8px 48px;
          align-items: center;
          gap: 16px;
          align-self: stretch;
        }

        button {
          border: 1px solid var(--Dark-Blue, #5658b7);
          border-radius: 6px;
          padding: 11px;
        }
      }

      .td-center {
        text-align: center;
      }

      .menuImg {
        margin-right: 8px;
      }

      .form-check-input {
        --bs-form-check-bg: unset;
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        vertical-align: top;
        appearance: none;
        background-color: var(--bs-form-check-bg);
        background-image: url('../images/icon/checkbox_unchecked.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 24px auto;
        border: none;
        transition: 0.2s ease-in-out;
        border-radius: 0.25rem;
        box-shadow: none;

        &:checked[type='checkbox'] {
          background-image: url('../images/icon/checkbox_checked.png');
          width: 24px;
          height: 24px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 24px auto;
        }
      }
    }

    p {
      color: var(--text-primary, #161616);
      font-family: 'Pretendard';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
      margin-bottom: 8px;
    }
  }
}

//QNA
#qna {
  background-color: #fafafd;

  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: center;
    align-self: stretch;

    .title-text {
      color: #000000;
      font-family: 'Pretendard';
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.16px;
    }

    button {
      border: 1px solid var(--Dark-Blue, #5658b7);
      border-radius: 6px;
      padding: 14px 16px;
      color: var(--text-primary, #161616);
      font-family: 'Pretendard';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;

      &:disabled {
        pointer-events: none;
        opacity: 1;
        color: var(--bs-btn-disabled-color, #35403a);
        background-color: #b1b3c7;
        border-color: #b1b3c7;
      }
    }
  }

  .qna-article {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-end;
    align-self: stretch;
    gap: 16px;
    border: 1px solid var(--border-border-02, #c6c6c6);
    border-radius: 14px;
    background: #fff;

    table {
      align-self: stretch;
      border-top: 1px solid var(--Weak-Gray, #f1f1f8);

      th {
        background: var(--Pale-Gray, #fafafd);
        color: var(--text-secondary, #525252);
        font-family: 'Pretendard';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.16px;
        text-align: left;
      }

      .th-center {
        text-align: center;
      }

      .th-right {
        text-align: right;
      }

      .popup-image {
        width: 80px;
        height: 55px;
      }

      td {
        text-align: left;

        .table-tr {
          background-color: #fafafd;
          padding: 8px 71px 8px 48px;
          align-items: center;
          gap: 16px;
          align-self: stretch;
        }

        button {
          border: 1px solid var(--Dark-Blue, #5658b7);
          border-radius: 6px;
          padding: 11px;
        }
      }

      .col-draghandle {
        width: 48px;
      }

      .col-popupNm {
        width: 298px;
      }

      .col-popupCost {
        width: 90px;
      }

      .col-popupInfo {
        width: 365px;
      }

      .col-popupView {
        width: 40px;
      }

      .col-popupModi {
        width: 71px;
      }

      .td-center {
        text-align: center;
      }

      .popupImg {
        margin-right: 8px;
        width: 60px;
        height: 60px;
      }
    }

    .align-left {
      align-self: flex-start;
      width: -webkit-fill-available;

      div {
        margin-bottom: 16px;

        .photo-imgbox {
          width: 90px;
          height: 90px;
          position: relative;
          margin-bottom: 0;

          .photo-img {
            width: 90px;
            height: 90px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
          }

          .photo-del {
            background-position: center;
            width: 14px;
            height: 14px;
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            right: 0px;
          }
        }

        .pic-btn {
          border: 1px solid #f1f1f8;
          border-radius: 0;
          width: 90px;
          height: 90px;
          display: flex;
          padding: 21px 18px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 8px;
          color: #6f6f6f;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0.32px;
          background-color: #fff;
          text-wrap: nowrap;
        }
      }
    }

    p {
      color: var(--text-primary, #161616);
      font-family: 'Pretendard';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
      margin-bottom: 8px;
    }

    input {
      border-radius: 6px;
      border: 1px solid var(--border-border-01, #e0e0e0);
      width: 100%;
      height: 48px;
      padding: 15px 16px;
    }

    .article-text {
      color: var(--Black, #000);
      font-family: 'Pretendard';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.16px;
    }

    .image-input {
      border: 1px solid var(--Weak-Gray, #f1f1f8);
      display: flex;
      width: 90px;
      height: 90px;
      padding: 21px 18px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;

      label {
        display: contents;
      }

      span {
        color: var(--text-helper, #6f6f6f);
        font-family: 'Pretendard';
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        // letter-spacing: 0.32px;
      }

      input[type='file'] {
        position: absolute;
        width: 0;
        height: 0;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
      }
    }

    .helper-text {
      color: var(--text-secondary, #525252);
      font-family: 'Pretendard';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.32px;
      margin-top: 8px;
    }

    .register {
      border-radius: 6px;
      background-color: var(--Dark-Blue, #5658b7);
      color: #fff;
      width: 57px;
      height: 48px;
    }

    .cancle-btn {
      border-radius: 6px;
      border: 1px solid var(--Dark-Blue, #5658b7);
      width: 57px;
      height: 48px;
      margin-right: 8px;
    }

    .upload-css {
      width: 90px;
      height: 90px;
      position: relative;
      margin-bottom: 0 !important;

      .close-img {
        width: 14px;
        height: 14px;
        position: absolute;
        top: 0;
        right: 0;
      }

      .select-img {
        width: 90px;
        height: 90px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}

//공지 디테일
#qna-detail {
  width: auto;
  background: var(--Pale-Gray, #fafafd);

  .title {
    display: flex;
    justify-content: space-between;
    margin: 24px 0;
    align-items: center;
    align-self: stretch;

    .title-text {
      color: #000000;
      font-family: 'Pretendard';
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.16px;
    }
  }

  .report-info-title {
    color: #161616;
    font-weight: 500;
    margin-bottom: 0;
  }

  .partner-detail-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: auto;
    height: auto;
    padding: 16px;
    gap: 16px;
    margin: 24px 0px 32px 0px;
    border-radius: 14px;
    border: 1px solid var(--border-border-02, #c6c6c6);
    background: #fff;

    .title-area {
      border-bottom: 0;

      h2 {
        font-weight: 700;
        font-style: normal;
        font-family: Pretendard;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; /* 155.556% */
        letter-spacing: 0.16px;
        margin: 0;
        color: #161616;
      }
    }

    .input-area {
      width: 100%;
    }

    .btn-area {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      align-self: stretch;
    }

    .form-box {
      .info-box {
        border: 1px solid #f1f1f8;
        width: 100%;

        .info-box-grid {
          display: grid;
          grid-template-columns: 125px 1fr;
          align-items: center;

          .grid-title {
            padding: 1rem 0.5rem;
            background-color: #fafafd;
            border-bottom: 1px solid #f1f1f8;
            border-right: 1px solid #f1f1f8;
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 400;
            color: #525252;
          }

          .add-div {
            margin-bottom: 16px;

            .form-control {
              border-radius: 6px;
              width: 100%;
            }
          }

          .grid-body {
            padding: 1rem 0.5rem;
            border-bottom: 1px solid #f1f1f8;
            font-size: 16px;
            font-weight: 500;
            color: #161616;
            line-height: unset;
            white-space: pre-line;
          }

          .state-label {
            padding: 1rem 0.5rem;
            border-bottom: 1px solid #f1f1f8;
            font-size: 16px;
            font-weight: 500;
            color: #161616;
            line-height: unset;

            &.prog {
              color: #42be65;
            }

            &.stay {
              color: #326bff;
            }

            &.end {
              color: #9b9b9b;
            }
          }

          .grid-img-body {
            padding: 1rem 0.5rem;
            border-bottom: 1px solid #f1f1f8;
            display: flex;
            align-items: center;
            gap: 8px;

            .banner-img {
              width: 328px;
              height: 82px;
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
              border-radius: 10px;
            }
          }
        }
      }
    }
  }
}

#qna-Register {
  background-color: #fafafd;

  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: center;
    align-self: stretch;

    .title-text {
      color: #000000;
      font-family: 'Pretendard';
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.16px;
    }

    button {
      border: 1px solid var(--Dark-Blue, #5658b7);
      border-radius: 6px;
      padding: 14px 16px;
      color: var(--text-primary, #161616);
      font-family: 'Pretendard';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
    }
  }

  .qna-article {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-end;
    align-self: stretch;
    gap: 16px;
    border: 1px solid var(--border-border-02, #c6c6c6);
    border-radius: 14px;
    background: #fff;

    .align-left {
      align-self: flex-start;
      width: -webkit-fill-available;

      .add-div {
        margin-bottom: 16px;

        .form-control {
          border-radius: 6px;
          width: 100%;
        }
      }
    }

    p {
      color: var(--text-primary, #161616);
      font-family: 'Pretendard';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
      margin-bottom: 8px;
    }

    .article-text {
      color: var(--Black, #000);
      font-family: 'Pretendard';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.16px;
    }

    .register {
      border-radius: 6px;
      background-color: #387fff;
      color: #fff;
      width: 57px;
      height: 48px;
    }

    .close-img {
      margin: 0 5px;
      width: 25px;
      height: 25px;
      cursor: pointer;
      align-self: center;
    }

    .cancle-btn {
      border-radius: 6px;
      border: 1px solid #387fff;
      width: 57px;
      height: 48px;
      margin-right: 8px;
    }

    .image-input {
      border: 1px solid var(--Weak-Gray, #f1f1f8);
      display: flex;
      width: 328px;
      height: 82px;
      padding: 21px 18px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;

      label {
        display: contents;
      }

      span {
        color: var(--text-helper, #6f6f6f);
        font-family: 'Pretendard';
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        // letter-spacing: 0.32px;
      }

      input[type='file'] {
        position: absolute;
        width: 0;
        height: 0;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
      }
    }

    .helper-text {
      color: var(--text-secondary, #525252);
      font-family: 'Pretendard';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.32px;
      margin-top: 8px;
    }

    .upload-css {
      width: 328px;
      height: 82px;
      position: relative;
      margin-bottom: 0 !important;

      .close-img {
        width: 7px;
        height: 7px;
        position: absolute;
        top: 0;
        right: 0;
      }

      .select-img {
        width: 328px;
        height: 82px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}

//가맹점 현황
#franKeyword {
  background-color: #fafafd;

  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: center;
    align-self: stretch;

    .title-text {
      color: #000000;
      font-family: 'Pretendard';
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.16px;
    }

    Button {
      border: 1px solid var(--Dark-Blue, #5658b7);
      border-radius: 6px;
      padding: 14px 16px;
      color: #ffffff;
      font-family: 'Pretendard';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
    }
  }

  .menu-height-article {
    display: flex;
    padding: 16px;
    flex-direction: column;
    border: 1px solid var(--border-border-02, #c6c6c6);
    border-radius: 14px;
    background: #fff;
    margin-bottom: 16px;
    width: 236px;

    h6 {
      font-size: 16px;
      margin-bottom: 16px;
      color: #000000;
      font-weight: 500;
    }

    .keyword-search {
      border-bottom: 1px solid #f1f1f8;
      padding-bottom: 16px;

      .form-control {
        width: 204px;
        margin-bottom: 4px;
      }

      Button {
        float: right;
      }
    }

    .keyword-search-scroll {
      height: 400px;
      overflow-y: scroll;
      margin-bottom: 16px;

      .scroll-p {
        padding: 1rem;
        border-bottom: 1px solid #f1f1f8;
        font-size: 16px;
        font-weight: 500;
        color: #161616;
        cursor: pointer;

        &:hover,
        &:active,
        &.active,
        &:focus {
          background-color: rgb(86, 88, 183, 8%);
          border-top: 1px solid #e3e3f8;
          border-bottom: 1px solid #e3e3f8;
          font-weight: bold;
        }
      }
    }

    .pagination {
      justify-content: center;
    }
  }

  .menu-article {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-end;
    align-self: stretch;
    gap: 16px;
    border: 1px solid var(--border-border-02, #c6c6c6);
    border-radius: 14px;
    background: #fff;
    width: 100%;

    .add-btn-title {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;

      h6 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0;
      }

      Button {
        background-color: #e3e3f8;
        border: none;

        .material-icons {
          color: #5658b7;
          font-weight: bold;
        }
      }
    }

    table {
      align-self: stretch;
      border-top: 1px solid var(--Weak-Gray, #f1f1f8);

      th {
        background: var(--Pale-Gray, #fafafd);
        color: var(--text-secondary, #525252);
        font-family: 'Pretendard';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.16px;
        text-align: left;
      }

      .th-center {
        text-align: center;
      }

      .th-right {
        text-align: right;
      }

      .th-left {
        text-align: left;
      }

      .td-btn-box {
        margin-top: 8px;

        Button {
          width: 35px;
          height: 32px;
          padding: 0;
        }
      }

      td {
        text-align: left;

        .table-tr {
          background-color: #fafafd;
          padding: 8px 71px 8px 48px;
          align-items: center;
          gap: 16px;
          align-self: stretch;
        }
      }

      .state-label {
        font-weight: 600;
        font-size: 16px;
        text-align: center;

        &.request {
          color: #1192e8;
        }

        &.sucess {
          color: #42be65;
        }

        &.cancel {
          color: #ff7b7b;
        }

        &.end {
          color: #9b9b9b;
        }
      }

      .td-text-title {
        width: 130px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
      }

      .td-text-detail {
        width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
      }

      .edit-line-table {
        display: flex;
        align-items: center;

        position: relative;

        .edit-btn {
          background-color: #e3e3f8;
          width: 42px;
          color: #5658b7;
          height: 35px;
          border: none;
          font-size: 12px;
          padding: 0;
          position: absolute;
          top: -6px;
          right: 0px;
        }
      }
    }

    p {
      color: var(--text-primary, #161616);
      font-family: 'Pretendard';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
      margin-bottom: 8px;
    }

    .article-text {
      color: var(--Black, #000);
      font-family: 'Pretendard';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.16px;
    }
  }
}

//상담예약
#booking {
  background-color: #fafafd;

  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: center;
    align-self: stretch;

    .title-text {
      color: #000000;
      font-family: 'Pretendard';
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.16px;
    }
  }

  .menu-top-article {
    display: flex;
    padding: 16px;
    flex-direction: column;
    gap: 16px;
    border: 1px solid var(--border-border-02, #c6c6c6);
    border-radius: 14px;
    background: #fff;
    margin-bottom: 16px;

    .grid-top-box {
      display: grid;
      grid-template-columns: 100px 1fr;
      align-items: center;

      .form-control {
        width: 288px;
      }
    }

    .register {
      border-radius: 6px;
      background-color: #387fff;
      color: #fff;
      width: 57px;
      height: 48px;
    }
  }

  .menu-article {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-end;
    align-self: stretch;
    gap: 16px;
    border: 1px solid var(--border-border-02, #c6c6c6);
    border-radius: 14px;
    background: #fff;

    table {
      align-self: stretch;
      border-top: 1px solid var(--Weak-Gray, #f1f1f8);

      th {
        background: var(--Pale-Gray, #fafafd);
        color: var(--text-secondary, #525252);
        font-family: 'Pretendard';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.16px;
        text-align: left;
      }

      .th-center {
        text-align: center;
      }

      .th-right {
        text-align: right;
      }

      .th-left {
        text-align: left;
      }

      td {
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        .table-tr {
          background-color: #fafafd;
          padding: 8px 71px 8px 48px;
          align-items: center;
          gap: 16px;
          align-self: stretch;
        }

        button {
          border: 1px solid var(--Dark-Blue, #5658b7);
          border-radius: 6px;
          padding: 7px;
          height: 42px;
          width: 75px;
          font-size: 14px;
        }
      }

      .state-label {
        font-weight: 600;
        font-size: 16px;
        text-align: center;

        &.sucess {
          color: #42be65;
        }

        &.cancel {
          color: #da1e28;
        }

        &.end {
          color: #9b9b9b;
        }
      }

      .td-text-title {
        width: 130px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
      }

      .td-text-detail {
        width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
      }
    }

    p {
      color: var(--text-primary, #161616);
      font-family: 'Pretendard';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
      margin-bottom: 8px;
    }

    input {
      border-radius: 6px;
      border: 1px solid var(--border-border-01, #e0e0e0);
      width: 100%;
      height: 48px;
      padding: 15px 16px;
    }

    .article-text {
      color: var(--Black, #000);
      font-family: 'Pretendard';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.16px;
    }
  }
}