.app-container {
  .app-contents {
    main.auth {
      padding: 0;
      background: var(--bs-white);
      display: flex;
      justify-content: center;
      align-items: center;
      .logo {
        display: block;
        margin: 0 auto 2rem;
        cursor: pointer;
      }
      .form-box {
        padding: 2.5rem 1.5rem;
        background: var(--bs-white);
        border-radius: 0.5rem;
        border: 1px solid var(--bs-border);
        .title-area {
          justify-content: center;
          border: 0;
          padding: 0;
          h3 {
            font-size: var(--fs-32);
            font-weight: 500;
          }
        }
      }
      .complete-wrap {
        p {
          font-size: var(--fs-20);
          font-weight: 500;
          text-align: center;
          strong {
            font-weight: 700;
            color: var(--bs-primary);
          }
        }
      }
    }
  }
}