.modal {
  &.show {
    .modal-dialog {
      transform: none !important;
    }
  }
  &.add-scroll-area {
    margin-left: -8px;
  }
  .modal-dialog {
    max-width: 480px;
    margin: 3rem auto;
    &.modal-dialog-centered {
      min-height: calc(100% - 6rem);
      height: calc(100% - 6rem);
    }
    &.modal-sm {
      max-width: 400px;
    }
    &.modal-lg {
      max-width: 880px;
    }
    &.modal-xl {
      max-width: 1400px;
      min-width: 1280px;
    }
    &.modal-right {
      width: 300px;
      min-height: 100%;
      height: 100%;
      margin: 0;
      align-items: flex-start;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50px, 0);
      .modal-content {
        border-radius: 0;
        width: 100%;
        height: 100%;
        overflow-y: auto;
      }
    }
    .modal-content {
      border: 0;
      border-radius: 0.25rem;
      .modal-header {
        border: 0;
        padding: 1rem;
        border-bottom: 1px solid var(--bs-border);
        .modal-title {
          font-weight: 700;
          font-size: var(--fs-18);
        }
        .btn-close {
          margin: 0 -0.75rem 0 auto;
          background-size: 0.85rem auto;
          padding: 0.75rem 1rem;
          opacity: 1 !important;
        }
      }
      .modal-body {
        padding: 1.5rem 1rem;
        font-weight: 500;
        [data-simplebar] {
          width: 100%;
        }
      }
      .modal-footer {
        padding-top: 0;
        border: 0;
        justify-content: center;
        .btn-lg {
          flex: 1;
        }
      }
    }
  }
}
#save-modal {
  position: absolute;
  top: 50%;
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%, 50%);
  .modal-content {
    min-width: 328px;
    .modal-body {
      padding: 1rem;
      p {
        color: #2c2f38;
        font-size: 16px;
        font-weight: 400;
      }
    }
    .modal-footer {
      background-color: transparent;
      height: 64px;
      Button {
        background: transparent;
        height: auto;
        width: 100%;
        color: #161616;
        border: none;
        padding: 0;
      }
    }
  }
}
#save-faild-modal {
  position: absolute;
  top: 50%;
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%, 50%);
  .modal-content {
    min-width: 328px;
    .modal-body {
      padding: 1rem;
      p {
        color: #2c2f38;
        font-size: 16px;
        font-weight: 400;
      }
    }
    .modal-footer {
      background-color: transparent;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 4px;
    }
  }
}
// 유동 상황판 - 가입 지역 리스트
#modal-map-list {
  .modal-content {
    overflow: hidden;
    .modal-body {
      padding: 0;
      overflow-y: auto;
      .btn-group {
        width: 100%;
        display: flex;
        flex-direction: column;
        label.btn-list {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          padding: 1rem 0.875rem;
          border: 0;
          border-radius: 0;
          border-bottom: 1px solid var(--bs-border);
          h6 {
            margin: 0;
            font-weight: 800;
            color: var(--bs-dark);
          }
          strong {
            color: var(--bs-primary);
          }
        }
        input {
          &:checked {
            & + label.btn-list {
              h6 {
                color: var(--bs-primary) !important;
              }
            }
          }
        }
      }
    }
  }
}

// 홍보 스케쥴 관리
#modal-prm-schedule {
  .list-prmType {
    li {
      background: rgba(var(--bs-primary-rgb), 0.1);
      border-radius: 0.25rem;
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      width: 120px;
      padding: 0.5rem;
      margin-right: 0.5rem;
      h6 {
        margin: 0;
        font-weight: 400;
        color: var(--bs-gray);
        font-size: var(--fs-14);
      }
      strong {
        font-size: var(--fs-14);
      }
    }
  }
  form {
    margin-top: 0.5rem;
    border-bottom: 1px solid var(--bs-border);
    .form-group {
      padding: 0.5rem 0;
      border-top: 1px solid var(--bs-border);
      margin: 0;
      .grid-line {
        display: grid;
        grid-template-columns: 100px 1fr;
        align-items: center;
        .grid-form {
          display: grid;
        }
      }
      .form-label {
        font-size: var(--fs-14);
        color: var(--bs-dark);
        font-weight: 600;
      }
      .datepicker-wrap.mw-100 {
        .react-datepicker-wrapper {
          width: inherit;
          .form-control {
            max-width: 200px !important;
          }
        }
      }
    }
  }
}
//업체검색 모달
#employ-modal {
  position: absolute;
  top: 50%;
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  .modal-content {
    min-width: 700px;
    .modal-header {
      p {
        color: #2c2f38;
        font-size: 16px;
        font-weight: 600;
      }
      img {
        cursor: pointer;
      }
    }
    .modal-body {
      padding: 0rem;
      .modal-fra-search {
        padding: 1rem;
        border-bottom: 1px solid #e0e0e0;
        display: grid;
        grid-template-columns: 1fr 60px;
        align-items: center;
        gap: 6px;

        .form-control {
          width: 100%;
        }
      }
      .modal-scroll-check {
        height: 380px;
        overflow-y: scroll;
        padding: 1rem 1rem 1rem 1rem;
        .search-field {
          display: flex;
            padding: 16px 0px;
            align-items: center;
            gap: 8px;
            align-self: stretch;
          p {
            color: #161616;
            font-weight: 500;
          }
        }
      }
    }
  }
}
#info-modal {
  position: absolute;
  top: 50%;
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  .modal-content {
    min-width: 696px;
    .modal-header {
      padding: 0.5rem 1rem;
      p {
        color: #2c2f38;
        font-size: 16px;
        font-weight: 600;
      }
      img {
        cursor: pointer;
      }
    }
    .modal-body {
      padding: 1rem;
      height: 696px;
      overflow-y: scroll;
      .modal-title-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 16px;
        .modal-label {
          font-size: 14px;
          font-weight: bold;
          padding: 4px 6px;
          border-radius: 12px;
          &.ING {
            color: #1192e8;
            background-color: rgba(17, 146, 232, 0.2);
          }
          &.END {
            color: #676767;
            background-color: rgba(103, 103, 103, 0.2);
          }
          &.REQ {
            color: #fea219;
            background-color: rgba(254, 164, 25, 0.2);
          }
        }
        .count-line {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 16px;
          justify-content: center;
          border: 1px solid #e0e0e0;
          padding: 0.5rem 1rem;
          .count-box {
            align-self: center;
            text-align: center;
            .title {
              font-size: 14px;
              font-weight: 400;
              color: #161616;
            }
            .count {
              font-size: 14px;
              font-weight: 600;
              color: #161616;
            }
          }
        }
      }
      .body-line {
        .form-box {
          margin-bottom: 52px;
          .member-title-text {
            font-size: 16px;
            font-weight: 600;
            color: #000000;
            margin-bottom: 0px;
          }
          .info-box {
            border: 1px solid #f1f1f8;
            width: 100%;
            padding: 1rem;
            .info-box-grid {
              display: grid;
              grid-template-columns: 80px 1fr;
              align-items: center;
              flex: 1;
              .grid-title {
                padding: 0.25rem 0rem;
                display: flex;
                align-items: center;
                font-size: 16px;
                font-weight: 400;
                color: #525252;
                flex: 120px 0 0;
              }
              .grid-body {
                padding: 0.25rem 0rem;
                font-size: 16px;
                font-weight: 500;
                color: #161616;
                line-height: unset;
                word-break: break-all;
              }
            }
            .d-flex {
              gap: 0;
              align-items: center;
            }
          }
        }
      }
    }
    .modal-footer {
      background-color: transparent;
      height: 64px;
      Button {
        background: transparent;
        height: auto;
        width: 100%;
        color: #161616;
        border: none;
        padding: 0;
      }
    }
  }
}
#member-modal {
  position: absolute;
  top: 50%;
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  .modal-content {
    min-width: 696px;
    .modal-header {
      padding: 0.5rem 1rem;
      p {
        color: #2c2f38;
        font-size: 16px;
        font-weight: 600;
      }
      img {
        cursor: pointer;
      }
    }
    .modal-body {
      padding: 1rem;
      height: 696px;
      overflow-y: scroll;
      .member-info {
        .profile-box {
          display: flex;
          align-items: center;
          gap: 16px;
          margin-bottom: 16px;
          .profile-img {
            border: 1px solid #e0e0e7;
            width: 90px;
            height: 90px;
            border-radius: 40px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
          }
          .profile-card-box {
            .label {
              display: block;
              padding: 0.2rem 0.5rem;
              font-size: 12px;
              font-weight: bold;
              display: inline-block;
              border-radius: 6px;
              &.public {
                color: #5658b7;
                background-color: rgba(86, 88, 183, 0.2);
              }
              &.vip {
                color: #ff7b7b;
                background-color: rgba(255, 123, 123, 0.2);
              }
            }
            .name {
              font-size: 20px;
              font-weight: bold;
              color: #161616;
            }
            .email-id {
              color: #6f6f6f;
              font-weight: 500;
              font-size: 16px;
            }
          }
        }
        .form-box {
          margin-bottom: 16px;
          .info-box {
            border: 1px solid #f1f1f8;
            width: 100%;
            .info-box-grid {
              display: grid;
              grid-template-columns: 125px 1fr;
              align-items: center;
              flex: 1;
              .grid-title {
                padding: 0.5rem 0.5rem;
                background-color: #fafafd;
                border-bottom: 1px solid #f1f1f8;
                border-right: 1px solid #f1f1f8;
                display: flex;
                align-items: center;
                font-size: 16px;
                font-weight: 400;
                color: #525252;
                flex: 120px 0 0;
              }
              .grid-body {
                padding: 0.5rem 0.5rem;
                border-bottom: 1px solid #f1f1f8;
                font-size: 16px;
                font-weight: 500;
                color: #161616;
                line-height: unset;
                word-break: break-all;
              }
            }
            .d-flex {
              gap: 0;
              align-items: unset;
            }
          }
        }
        table {
          align-self: stretch;
          border-top: 1px solid var(--Weak-Gray, #f1f1f8);
          th {
            background: var(--Pale-Gray, #fafafd);
            color: var(--text-secondary, #525252);
            font-family: 'Pretendard';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.16px;
            text-align: left;
          }
          .th-center {
            text-align: center;
          }
          .th-right {
            text-align: right;
          }
          .th-left {
            text-align: left;
          }
          td {
            text-align: left;
            .table-tr {
              background-color: #fafafd;
              padding: 8px 71px 8px 48px;
              align-items: center;
              gap: 16px;
              align-self: stretch;
            }
            button {
              border: 1px solid var(--Dark-Blue, #5658b7);
              border-radius: 6px;
              padding: 7px;
              height: 42px;
              width: 75px;
              font-size: 14px;
            }
          }
          .state-label {
            font-weight: 600;
            font-size: 16px;
            text-align: center;
            &.sucess {
              color: #42be65;
            }
            &.cancel {
              color: #da1e28;
            }
            &.end {
              color: #9b9b9b;
            }
          }
          .td-text-title {
            width: 130px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            word-break: break-all;
          }
          .td-text-detail {
            width: 200px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            word-break: break-all;
          }
        }
      }
    }
  }
}
#pay-modal {
  position: absolute;
  top: 50%;
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  .modal-content {
    min-width: 480px;
    .modal-header {
      padding: 0.5rem 1rem;
      p {
        color: #2c2f38;
        font-size: 16px;
        font-weight: 600;
      }
      img {
        cursor: pointer;
      }
    }
    .modal-body {
      .align-left {
        align-self: flex-start;
        width: -webkit-fill-available;
        .add-div {
          margin-bottom: 32px;
          .form-control {
            border-radius: 6px;
            width: 100%;
          }
          p {
            margin-bottom: 0.5rem;
          }
        }
      }
      .register {
        border-radius: 6px;
        background-color: #387fff;
        color: #fff;
        width: 75px;
        height: 48px;
      }
      .cancle-btn {
        border-radius: 6px;
        border: 1px solid #387fff;
        width: 57px;
        height: 48px;
        margin-right: 8px;
      }
    }
  }
}
